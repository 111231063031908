
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { EmailVerificationCheckerService } from '@/core/services/emailVerificationCheckerService';
import { inject } from 'vue';
import { defineComponent } from 'vue';
var emailVerificationCheckerService: EmailVerificationCheckerService;
export default defineComponent({
    name: 'ErrorJwtExpired',
    created() {
        emailVerificationCheckerService = inject(Services.EmailVerificationChecker) as EmailVerificationCheckerService;
        emailVerificationCheckerService.stop();
    },
    data() {
        let resources = inject(Services.Resources) as Resources;
        return {
            errorMessage: resources.errors.authnExpired
        }
    }
})
