/* eslint-disable */
import { BackendServiceBase } from "./backendServiceBase";
import { ErrorType } from "../models/metadata/error";
import { CheckEmailVerifiedResponse } from "../models/responses/checkEmailVerifiedResponse";

export class CheckEmailVerifiedService extends BackendServiceBase<any, CheckEmailVerifiedResponse>{
    
    constructor(backendEndpoint: string) {
        super(backendEndpoint, ErrorType.Silent);
    }

    protected buildFetch(headers: any): Promise<Response> {
        return fetch(`${this.backendEndpoint}/checkEmailVerified`, {
            headers: headers,
            method: 'POST'
        });
    }
    
}