import { Router } from "vue-router";
import { CheckEmailVerifiedService } from "./checkEmailVerifiedService";
import { JwtWrapper } from "./jwtWrapper";

export class EmailVerificationCheckerService {

    private static hasCheckingStarted = false;
    private static checkIntervalIdentifier = 0;


    constructor(
        private readonly checkEmailVerifiedService: CheckEmailVerifiedService
    ) { }

    public start(router: Router) {

        if (EmailVerificationCheckerService.hasCheckingStarted)
            return;

        EmailVerificationCheckerService.hasCheckingStarted = true;

        const checkEmailVerifiedService: CheckEmailVerifiedService = this.checkEmailVerifiedService;

        const stop = this.stop;
        const checkIntervalIdentifier = setInterval(async () => {

            const response = await checkEmailVerifiedService.handle(undefined, () => {
                stop();
                router.push('/authnExpired')
            });

            if (response.isSuccess && response.emailVerified) {
                stop();
                if (!JwtWrapper.jwtClaims.needToFillAdditionalData) {
                    window.location.href = JwtWrapper.getContinueEndpoint();
                    return;
                }
                JwtWrapper.jwtClaims.emailVerified = true;
                router.push('/emailVerified');
            }

        }, 5000);
        EmailVerificationCheckerService.checkIntervalIdentifier = checkIntervalIdentifier;

    }

    public stop() {

        if (!EmailVerificationCheckerService.hasCheckingStarted)
            return;

        window.clearInterval(EmailVerificationCheckerService.checkIntervalIdentifier);

        EmailVerificationCheckerService.hasCheckingStarted = false;
    }
}