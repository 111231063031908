
import { defineComponent } from 'vue';
import { JwtWrapper } from './core/services/jwtWrapper';
import { inject } from 'vue';
import { Services } from './core/models/metadata/services';
import { Resources } from './core/models/metadata/resources';

export default defineComponent({
  created() {
    const { state, session_token } = this.$route.query as { state: string, session_token: string };
    JwtWrapper.tryPersist(state, session_token, this.$router);
  },
  data() {
    const resources = inject(Services.Resources) as Resources;
    return {
      needHelp: resources.messages.needHelp,
      contactCS: resources.messages.contactCS,
      contactFormUri: process.env.VUE_APP_USER_MANAGEMENT_SITE_CONTACT_FORM_URL
    }
  }
})
