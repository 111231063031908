/* eslint-disable */
export class StringHelper {
    public formatValues(formatable: string, parameters: any[]): string {
        if (parameters.length) {
            for (let i = 0; i < parameters.length; i++) {
                formatable = formatable.replace(`{${i}}`, parameters[i]);
            }
        }
        return formatable;
    }
}