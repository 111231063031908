
import { ErrorsService } from "@/core/services/errorsService";
import { PropType, defineComponent } from "vue";
import { Error, ErrorType } from "@/core/models/metadata/error";
import { ref } from "vue";

export default defineComponent({
  name: "ErrorsManager",
  props: {
    errorType: { required: true, type: String as PropType<ErrorType> },
  },
  data() {
    return {
      errorsProperty: ref(ErrorsService.getAll()),
    };
  },
  computed: {
    errors(): Error[] {
      return this.errorsProperty;
    },
  },
});
