/* eslint-disable */

import { BackendServiceBase } from "./backendServiceBase";
import { UpdateUserResponse } from "../models/responses/updateUserResponse";
import { UpdateUserRequest } from "../models/requests/updateUserRequest";
import { Resources } from "../models/metadata/resources";
import { ErrorType } from "../models/metadata/error";

export class UpdateUserService extends BackendServiceBase<UpdateUserRequest, UpdateUserResponse>{
    
    constructor(private readonly resources: Resources, backendEndpoint: string) {
        super(backendEndpoint, ErrorType.UpdateFields);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.updateUser;
    }
    protected buildFetch(headers: any, request: UpdateUserRequest): Promise<Response> {
        return fetch(`${this.backendEndpoint}/insertUserData`, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(request)
        });
    }
    
}