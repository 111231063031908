import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "componentTitle" }
const _hoisted_2 = { class: "card-text" }
const _hoisted_3 = { class: "buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.emailVerifiedTitle), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.emailVerifiedMessage), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "blueBgButton",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
      }, _toDisplayString(_ctx.emailVerifiedText), 1)
    ])
  ]))
}