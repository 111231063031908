

import { Resources } from "@/core/models/metadata/resources";
import { ResendEmailVerificationService } from "@/core/services/resendEmailVerificationService";
import RadialSpinner from "@/components/shared/RadialSpinner.vue";
import ErrorsManager from "@/components/shared/ErrorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error";
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services";
import { JwtWrapper } from "@/core/services/jwtWrapper";
import { EmailVerificationCheckerService } from "@/core/services/emailVerificationCheckerService";

var emailVerificationService: ResendEmailVerificationService;
var emailVerificationCheckerService: EmailVerificationCheckerService;
export default defineComponent({
  created() {
    emailVerificationCheckerService = inject(Services.EmailVerificationChecker) as EmailVerificationCheckerService;
    emailVerificationCheckerService.start(this.$router);
  },
  data() {
    let resources = inject(Services.Resources) as Resources;
    emailVerificationService = inject(Services.ResendEmailVerification) as ResendEmailVerificationService;
    return {
      emailText: resources.buttons.resendEmail,
      emailTitle: resources.titles.verifyYourEmail,
      validationEmailSent: resources.messages.validationEmailSent,
      verifyYourEmail: resources.messages.verifyYourEmail,
      userEmail: JwtWrapper.jwtClaims?.email,
      isLoading: false,
      errorType: ErrorType.ResendEmailVerification,
    };
  },
  methods: {
    async sendEmailVerificationRequest() {
      this.isLoading = true;
      await emailVerificationService.handle();
      this.isLoading = false;
    }
  },
  components: {
    RadialSpinner,
    ErrorsManager,
  },
});
