/* eslint-disable */
import { ErrorType } from "../models/metadata/error";
import { BaseResponse } from "../models/responses/baseResponse";
import { ErrorsService } from "./errorsService";
import { JwtWrapper } from "./jwtWrapper";


export abstract class BackendServiceBase<TQuery, TResponse extends BaseResponse> {

    private readonly invariantHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': ''
    }
    constructor(
        protected readonly backendEndpoint: string,
        private readonly errorType: ErrorType = ErrorType.Silent
        ) { }

    protected getHeaders(): any {
        this.invariantHeaders.Authorization = `Bearer ${JwtWrapper.rawToken}`;
        return this.invariantHeaders;
    }
    protected defineErrorMessage(query?: TQuery): string {
        return '';
    }
    protected abstract buildFetch(headers: any, query?: TQuery): Promise<Response>;
    public async handle(query?: TQuery, unauthorizedHandler?: () => void | Promise<void>): Promise<TResponse> {
        try {
            ErrorsService.deleteByType(this.errorType);

            const response = await this.buildFetch(this.getHeaders(), query);

            if(response.status === 401 && unauthorizedHandler){                
                await unauthorizedHandler();
            }

            const json = await response.json();
            const result = json as TResponse;

            if (!result.isSuccess) {
                this.introduceErrorIfNotEmpty(query);
            }

            return result;
        } catch (e) {

            this.introduceErrorIfNotEmpty(query);
            
            return new Promise((res, _) => res({ isSuccess: false } as TResponse));
        }
    }
    private introduceErrorIfNotEmpty(query?: TQuery): void {
        const error = this.defineErrorMessage(query);
        if (error)
            ErrorsService.push(error, this.errorType);
    }

}
