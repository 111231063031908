/* eslint-disable */

import { BackendServiceBase } from "./backendServiceBase";
import { ResendEmailVerificationResponse } from "../models/responses/resendEmailVerificationResponse";
import { Resources } from "../models/metadata/resources";
import { ErrorType } from "../models/metadata/error";

export class ResendEmailVerificationService extends BackendServiceBase<any, ResendEmailVerificationResponse>{
    
    constructor(private readonly resources: Resources, backendEndpoint: string) {
        super(backendEndpoint, ErrorType.ResendEmailVerification);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.resendEmailVerification;
    }
    protected buildFetch(headers: any): Promise<Response> {
        return fetch(`${this.backendEndpoint}/resendEmailVerificationCustomSignup`, {
            headers: headers,
            method: 'POST'
        });
    }
    
}