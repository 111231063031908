import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CustomPagesEntryPoint from "../views/CustomPagesEntryPoint.vue";
import EmailVerificationPending from "../views/EmailVerificationPending.vue";
import EmailVerified from "../views/EmailVerified.vue";
import CaptureExtraFields from "../views/CaptureExtraFields.vue";
import ErrorJwtExpired from "../views/ErrorJwtExpired.vue";
import { JwtWrapper } from "@/core/services/jwtWrapper";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: CustomPagesEntryPoint,
  },
  {
    path: "/emailVerificationPending",
    name: "emailVerificationPending",
    component: EmailVerificationPending,
  },
  {
    path: "/emailVerified",
    name: "emailVerified",
    component: EmailVerified,
  },
  {
    path: "/userData",
    name: "userData",
    component: CaptureExtraFields,
  },  
  {
    path: "/authnExpired",
    name: "errorJwtExpired",
    component: ErrorJwtExpired,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const homeOrErrorRouteNames = [ 'home', 'errorJwtExpired']
const guardian = (to: any, from: any, next: any) => { 

  const isNavigationToEmailVerificationPending = to.matched.some(
    (record: any) => record.name === "emailVerificationPending"
  );
  
  
  const isNavigationToHomeOrError = to.matched.some(
    (record: any) => homeOrErrorRouteNames.includes(record.name)
  );

  const isEmailVerified = JwtWrapper.jwtClaims?.emailVerified;

  //Potential manual/malicious navigation
  if(isEmailVerified && isNavigationToEmailVerificationPending){
    next({
      name: 'emailVerified'
    });
    return;
  }

  //Potential manual/malicious navigation, redirect when email is not verified 
  //and navigating to other than email verification or home or error views
  if(!isEmailVerified && !isNavigationToEmailVerificationPending && !isNavigationToHomeOrError){
    next({
      name: 'emailVerificationPending'
    });
    return;
  }
  
  //Normal not protected navigation
  next();
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(guardian);

export { router };




