
export enum Title {
    DR = "Dr",
    PROFESSOR = "Professor",
    MISS = "Miss",
    MR = "Mr",
    MS = "Ms",
    MX = "Mx",
    BARON = "Baron",
    BARONESS = "Baroness",
    BRIGADIER = "Brigadier",
    CAPTAIN = "Captain",
    COL = "Col",
    DAME = "Dame",
    DATO_FR = "Dato' Dr",
    EARL = "Earl",
    EMER_PROF = "Emer Prof",
    FR = "Fr",
    HON = "Hon",
    HRH = "HRH",
    LADY = "Lady",
    LORD = "Lord",
    LT_CDR = "Lt Cdr",
    LT_COL = "Lt Col",
    MAJOR = "Major",
    MRS = "Mrs",
    PROF_DAME = "Prof Dame",
    PROF_DR = "Prof Dr",
    PROF_LORD = "Prof Lord",
    PROF_SIR = "Prof Sir",
    PROVOST = "Provost",
    REV = "Rev",
    REV_DR = "Rev Dr",
    RT_HON = "Rt Hon",
    RT_HON_DR = "Rt Hon Dr",
    SIR = "Sir",
    SISTER = "Sister",
    SQDRN_LDR = "Sqdrn Ldr",
    VISCOUNT = "Viscount",
}

