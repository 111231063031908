import SignJwt from 'jwt-encode'
import DecodeJwT from 'jwt-decode'
import { TokenInfo } from '../models/metadata/tokenInfo';
import { Router } from 'vue-router';

export class JwtWrapper {
    static jwtClaims: TokenInfo;
    static secret: string;
    static state: string;
    static persisted: boolean;
    static rawToken: string;

    static encode(): string {
        return SignJwt(this.jwtClaims, this.secret);
    }
    static tryPersist(state: string, token: string, router: Router): boolean {
        if (this.persisted)
            return true;
        try {
            const tokenIsNullOrUndefined = !token;
            const stateIsNullOrUndefined = !state;
            const session_token: string = tokenIsNullOrUndefined ? localStorage.getItem('jwt')! : token;
            const session_state: string = stateIsNullOrUndefined ? localStorage.getItem('state')! : state;
            this.persisted = true;
            this.jwtClaims = DecodeJwT(session_token);
            this.rawToken = session_token;
            this.state = session_state;
            const expirationDifference = this.jwtClaims.exp * 1000 - Date.now();
            const timeForTokenToExpire = expirationDifference > 0 ? expirationDifference : 0;
            setTimeout(() => {
                router.push('/authnExpired');
            }, timeForTokenToExpire);
            if (!tokenIsNullOrUndefined && !stateIsNullOrUndefined) {
                localStorage.setItem('jwt', session_token);
                localStorage.setItem('state', session_state);
            }

        } catch {
            this.persisted = false;
        }
        return this.persisted;
    }

    static getContinueEndpoint() {
        return `${process.env.VUE_APP_AUTH0_DOMAIN}/continue?state=${this.state}`;
    }
}