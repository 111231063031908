import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { createVuetify } from "vuetify/lib/framework.mjs";
import { VTextField, VForm, VField, VMessages, VSelect, VIcon } from "vuetify/lib/components/index.mjs";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { EmailVerificationCheckerService } from "./core/services/emailVerificationCheckerService";
import { Services } from "./core/models/metadata/services";
import { CheckEmailVerifiedService } from "./core/services/checkEmailVerifiedService";
import { ResendEmailVerificationService } from "./core/services/resendEmailVerificationService";
import { Resources } from "./core/models/metadata/resources";
import { UpdateUserService } from "./core/services/updateUserService";
import { StringHelper } from "./core/services/stringHelper";
import { ValidationRulesService } from "./core/services/validationRulesService";
import "vuetify/styles";

const vuetify = createVuetify({
    components: {
        VTextField,
        VForm,
        VField,
        VMessages,
        VSelect,
        VIcon,
    },
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
});
const app = createApp(App).use(vuetify).use(router);

injectServices()
    .then(() => app.mount("#app"));


function injectServices(): Promise<void> {

    const checkEmailVerifiedService = new CheckEmailVerifiedService(process.env.VUE_APP_BACKEND_BASE_URL);

    app.provide<EmailVerificationCheckerService>(Services.EmailVerificationChecker,
        new EmailVerificationCheckerService(checkEmailVerifiedService));
    
    const stringHelper = new StringHelper();

    return new Promise((res) => {
        fetch('resources/resources.json')
            .then(res => res.json())
            .then((resources: Resources) => {

                app.provide<ValidationRulesService>(Services.ValidationRules, new ValidationRulesService(resources, stringHelper));

                app.provide<Resources>(Services.Resources, resources);

                app.provide<ResendEmailVerificationService>(Services.ResendEmailVerification,
                    new ResendEmailVerificationService(resources, process.env.VUE_APP_BACKEND_BASE_URL));

                app.provide<UpdateUserService>(Services.UpdateUser,
                    new UpdateUserService(resources, process.env.VUE_APP_BACKEND_BASE_URL))

                res();
            });
    });
}
