import { reactive } from "vue";
import { Error, ErrorType } from "../models/metadata/error";

export class ErrorsService {
    private static errors: Error[] = reactive([]);
    public static push(message: string, errorType: ErrorType): void {
        this.errors.push({
            id: crypto.randomUUID(),
            message: message,
            type: errorType
        });
    }
    public static delete(error: Error): void {
        const index = this.errors.indexOf(error);
        ErrorsService.errors.splice(index, 1);
    }
    public static getAll(): Error[]{
        return ErrorsService.errors;
    }
    public static deleteAll(): void {
        while(ErrorsService.errors.length){
            this.errors.splice(0, 1);
        }
    }
    public static deleteByType(errorType: ErrorType): void{
        const currentErrorsForType = ErrorsService.errors.filter(error => error.type === errorType);
        if(!currentErrorsForType.length)
            return;
        currentErrorsForType.forEach(error => ErrorsService.errors.splice(ErrorsService.errors.indexOf(error), 1));
    }
    
}
