export interface Error{
    message: string,
    id: string,
    type: ErrorType
}

export enum ErrorType{
    UpdateFields = 'updateFields',
    ResendEmailVerification = 'resendEmailVerification',
    Silent = 'silent'
}