
import { JwtWrapper } from "@/core/services/jwtWrapper";
import { defineComponent } from "vue";
import RadialSpinner from '../components/shared/RadialSpinner.vue'

export default defineComponent({
  name: "CustomPagesEntryPoint",
  components:{
    RadialSpinner
  },
  created(){
    const { state, session_token } = this.$route.query as { state: string, session_token: string};
    if(!JwtWrapper.tryPersist(state, session_token, this.$router))
      return;
    const routeToRedirect = JwtWrapper.jwtClaims.emailVerified === true ? '/userData' : '/emailVerificationPending';
    this.$router.push(routeToRedirect);
  },
});
